import type { FormKitNode } from "@formkit/core"

/**
 * @privateRemarks
 * This file was generated by the FormKit CLI and should not be manually
 * edited unless you’d like to "eject" from the CLI’s ability to update it.
 *
 * @checksum - 98a276d6d53f2b0c5e67342d17fefbdfcf335b1d7d09bc78a3584ad4504f3c06
 * @variables - radius=rounded,accentColor=blue,colorTemperature=neutral,spacing=2,scale=base,inputShadow=shadow,baseColorShade=600,borderShadeLightMode=400,borderShadeDarkMode=500,inputMaxWidth=max-w-none,tagRadius=rounded,swatchRadius=rounded
 * @theme - regenesis
 **/

/**
 * This is the theme function itself, it should be imported and used as the
 * config.rootClasses function. For example:
 *
 * ```js
 * import { theme } from './formkit.theme'
 * import { defineFormKitConfig } from '@formkit/vue'
 *
 * export default defineFormKitConfig({
 *   config: {
 *     rootClasses: theme
 *   }
 * })
 * ```
 **/
export function rootClasses(
  sectionName: string,
  node: FormKitNode
): Record<string, boolean> {
  const key = `${node.props.type}__${sectionName}`
  const semanticKey = `formkit-${sectionName}`
  const familyKey = node.props.family
    ? `family:${node.props.family}__${sectionName}`
    : ""
  const memoKey = `${key}__${familyKey}`
  if (!(memoKey in classes)) {
    const sectionClasses = classes[key] ?? globals[sectionName] ?? {}
    sectionClasses[semanticKey] = true
    if (familyKey in classes) {
      classes[memoKey] = { ...classes[familyKey], ...sectionClasses }
    } else {
      classes[memoKey] = sectionClasses
    }
  }
  return classes[memoKey] ?? { [semanticKey]: true }
}

/**
 * These classes have already been merged with globals using tailwind-merge
 * and are ready to be used directly in the theme.
 **/
const classes: Record<string, Record<string, boolean>> = {
  "family:button__wrapper": {
    "group-data-[disabled=true]:grayscale": false
  },
  "family:button__input": {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "font-bold": false,
    rounded: false,
    "outline-none": false,
    flex: false,
    "!text-sm": false,
    "px-7": false,
    "py-3": false,
    "items-center": false,
    "mb-1.5": false,
    "text-sm": false,
    "ring-offset-2": false,
    "ring-blue-500": false,
    "focus-visible:ring-2": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    shadow: false,
    "group-data-[prefix-icon]:pl-5": false,
    "group-data-[suffix-icon]:pr-5": false,
    border: false,
    "border-blue-600": false,
    "text-blue-600": false,
    "group-[]/repeater:shadow-sm": false,
    "group-[]/multistep:shadow-sm": false,
    "dark:border-blue-500": false
  },
  "family:box__wrapper": {
    "inline-flex": false,
    "items-center": false,
    "mb-1": false,
    "group-data-[multiple]:mb-0": false
  },
  "family:box__legend": {
    block: false,
    "text-neutral-700": false,
    "text-sm": false,
    "font-bold": false,
    "dark:text-neutral-300": false,
    "mb-2": false
  },
  "family:box__input": {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    peer: false,
    "pointer-events-none": false,
    absolute: false,
    "h-0": false,
    "w-0": false,
    "overflow-hidden": false,
    "opacity-0": false
  },
  "family:box__decorator": {
    "mr-1.5": false,
    "bg-white": false,
    "ring-blue-500": false,
    "peer-checked:border-blue-600": false,
    relative: false,
    block: false,
    "text-lg": false,
    "w-[1em]": false,
    "aspect-[1/1]": false,
    border: false,
    "border-neutral-400": false,
    "text-transparent": false,
    "peer-checked:bg-blue-50": false,
    "peer-checked:text-blue-600": false,
    "peer-focus-visible:ring-2": false,
    "peer-focus-visible:ring-offset-1": false,
    "select-none": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    "peer-disabled:bg-neutral-100": false,
    "group-data-[disabled]:grayscale": false,
    shadow: false,
    "peer-disabled:cursor-not-allowed": false,
    "group-[]/repeater:shadow-none": false,
    "group-[]/multistep:shadow-none": false,
    "dark:border-neutral-500": false,
    "dark:bg-transparent": false,
    "dark:ring-offset-blue-500": false,
    "dark:peer-focus-visible:ring-1": false,
    "dark:peer-disabled:bg-neutral-600/50": false,
    "dark:peer-checked:bg-blue-900": false,
    "dark:peer-checked:text-blue-400": false
  },
  "family:box__decoratorIcon": {
    absolute: false,
    "left-1/2": false,
    "top-1/2": false,
    flex: false,
    "h-full": false,
    "w-full": false,
    "-translate-x-1/2": false,
    "-translate-y-1/2": false
  },
  "family:box__option": {
    "mb-1.5": false,
    "last:mb-0": false,
    "data-[disabled]:opacity-50": false,
    "data-[disabled]:select-none": false,
    "group-data-[disabled]:data-[disabled]:opacity-100": false
  },
  "family:box__label": {
    block: false,
    "text-neutral-700": false,
    "text-sm": false,
    "font-bold": false,
    "mb-1": false,
    "!mb-0": false,
    "!font-normal": false,
    "!text-sm": false,
    "dark:text-neutral-300": false
  },
  "family:box__optionHelp": {
    "text-neutral-500": false,
    "text-xs": false,
    "-mt-1": false,
    "mb-1.5": false,
    "ml-[min(2em,1.7rem)]": false,
    relative: false,
    "left-px": false
  },
  "family:box__help": {
    "text-neutral-500": false,
    "text-xs": false,
    "dark:text-neutral-400": false,
    "mb-1": false,
    "group-data-[multiple]:mb-2": false,
    "group-data-[multiple]:-mt-1.5": false
  },
  "family:text__wrapper": {
    flex: false,
    "flex-col": false,
    "items-start": false,
    "justify-start": false,
    "mb-1.5": false,
    "last:mb-0": false
  },
  "family:text__label": {
    block: false,
    "text-neutral-700": false,
    "text-sm": false,
    "font-bold": false,
    "dark:text-neutral-300": false,
    "!inline-flex": false,
    "mb-1": false
  },
  "family:text__inner": {
    "text-base": false,
    flex: false,
    "items-center": false,
    "w-full": false,
    "py-2": false,
    "px-3": false,
    rounded: false,
    border: false,
    "border-neutral-400": false,
    "bg-white": false,
    "focus-within:ring-1": false,
    "focus-within:!ring-blue-500": false,
    "focus-within:!border-blue-500": false,
    "group-data-[invalid]:border-red-500": false,
    "group-data-[invalid]:ring-1": false,
    "group-data-[invalid]:ring-red-500": false,
    "group-data-[disabled]:bg-neutral-100": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    shadow: false,
    "group-[]/repeater:shadow-none": false,
    "group-[]/multistep:shadow-none": false,
    "dark:bg-transparent": false,
    "dark:border-neutral-500": false,
    "dark:group-data-[disabled]:bg-neutral-800/5": false,
    "dark:group-data-[invalid]:border-red-500": false,
    "dark:group-data-[invalid]:ring-red-500": false
  },
  "family:text__input": {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "text-base": false,
    "text-neutral-700": false,
    "min-w-0": false,
    "min-h-[1.5em]": false,
    grow: false,
    "outline-none": false,
    "bg-transparent": false,
    "selection:bg-green-main": false,
    "placeholder:text-neutral-400": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    "dark:placeholder-neutral-400/50": false,
    "dark:text-neutral-300": false,
    "border-none": false,
    "p-0": false,
    "focus:ring-0": false
  },
  "family:text__prefixIcon": {
    flex: false,
    "items-center": false,
    "-ml-1": false,
    "mr-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "text-neutral-600": false,
    "dark:text-neutral-300": false
  },
  "family:text__suffixIcon": {
    flex: false,
    "items-center": false,
    "-mr-1": false,
    "ml-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "text-neutral-600": false,
    "dark:text-neutral-300": false
  },
  "family:dropdown__wrapper": {
    "mb-1.5": false
  },
  "family:dropdown__inner": {
    relative: true,
    flex: true,
    "items-center": true,
    "bg-white": true,
    border: true,
    "border-neutral-400": true,
    rounded: true,
    "group-data-[is-multiline]:!rounded": true,
    "focus-within:ring-1": false,
    "focus-within:!ring-blue-500": false,
    "focus-within:!border-blue-500": false,
    "group-data-[invalid]:border-negative-main": true,
    "group-data-[invalid]:ring-1": false,
    "group-data-[invalid]:ring-red-500": false,
    "group-data-[disabled]:bg-neutral-100": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    shadow: true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "dark:bg-transparent": true,
    "dark:border-neutral-500": true,
    "dark:group-data-[disabled]:bg-neutral-700/40": true,
    "dark:group-data-[invalid]:border-red-500": true,
    "dark:group-data-[invalid]:ring-red-500": true
  },
  "family:dropdown__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    grow: true,
    "p-2": true,
    "pr-0": true,
    "pl-3": true,
    "text-base": true,
    "text-neutral-700": true,
    "text-ellipsis": true,
    "min-w-0": true,
    "outline-none": true,
    "bg-transparent": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "placeholder:text-neutral-400": true,
    "selection:bg-green-main": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-300": true,
    "border-none": true,
    "focus:ring-0": true,
    "bg-none": true
  },
  "family:dropdown__listboxButton": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "text-base": true,
    flex: true,
    "items-center": true,
    "text-neutral-700": true,
    "z-10": true,
    "dark:text-neutral-300": true,
    "data-[disabled]:cursor-not-allowed": true
  },
  "family:dropdown__removeSelection": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "text-base": true,
    flex: true,
    "items-center": true,
    "text-neutral-700": true,
    "hover:text-red-400": true,
    "z-10": true,
    "dark:text-neutral-300": true
  },
  "family:dropdown__controlLabel": {
    absolute: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true
  },
  "family:dropdown__selectIcon": {
    "text-base": true,
    "inline-flex": true,
    "justify-center": true,
    "w-[2.5em]": true,
    relative: true,
    "my-auto": true,
    "[&>svg]:w-[1em]": true,
    "[&>svg]:mx-auto": true
  },
  "family:dropdown__closeIcon": {
    "text-base": true,
    "w-[0.75em]": true,
    relative: true,
    "m-auto": true
  },
  "family:dropdown__prefixIcon": {
    flex: true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!ml-2": true,
    "!mr-0": true,
    "text-neutral-600": true,
    "dark:text-neutral-300": true
  },
  "family:dropdown__suffixIcon": {
    flex: true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!mr-2": true,
    "!ml-0": true,
    "text-neutral-600": true,
    "dark:text-neutral-300": true
  },
  "family:dropdown__dropdownWrapper": {
    rounded: true,
    "shadow-lg": true,
    "mt-1": true,
    "overflow-clip": true,
    "empty:hidden": true,
    border: true,
    "border-neutral-300": true,
    "dark:border-neutral-600": true,
    "group-data-[expanded]:opacity-100": true,
    "group-data-[overscroll]:m-0": true,
    "group-data-[overscroll]:shadow-none": true,
    "group-data-[overscroll]:border-none": true
  },
  "family:dropdown__listitemGroup": {
    "group/optgroup": true,
    "last:pb-0": true,
    "border-t": true,
    "border-b": true,
    "-mb-px": true,
    "border-neutral-300": true,
    "dark:border-neutral-600": true
  },
  "family:dropdown__groupLabel": {
    block: true,
    "pt-1.5": true,
    "pb-1": true,
    "px-2.5": true,
    "font-bold": true,
    "pointer-events-none": true,
    "text-neutral-600": true,
    "dark:text-neutral-300": true
  },
  "family:dropdown__listbox": {
    "bg-white": true,
    rounded: true,
    "empty:hidden": true,
    "dark:bg-neutral-800": true,
    "group-data-[overscroll]:shadow-lg": true,
    "group-data-[overscroll]:border": true,
    "group-data-[overscroll]:border-neutral-300": true,
    "group-data-[overscroll]:dark:border-neutral-600": true
  },
  "family:dropdown__listitem": {
    relative: true,
    flex: true,
    "items-center": true,
    "px-2": true,
    "py-1.5": true,
    "first:pt-2": true,
    "last:pb-2": true,
    "text-neutral-700": true,
    "text-base": true,
    "data-[is-active]:bg-green-main": true,
    "data-[is-active]:text-white": true,
    "dark:text-neutral-200": true,
    "dark:data-[is-active]:text-neutral-700": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "data-[is-active]:first:before:rounded": true,
    "data-[is-active]:first:before:rounded-b-none": true,
    "data-[is-active]:last:before:rounded": true,
    "data-[is-active]:last:before:rounded-t-none": true,
    "data-[is-active]:first:last:before:rounded": true,
    "data-[is-active]:before:ring-1": true,
    "data-[is-active]:before:ring-blue-500": true,
    "data-[is-active]:before:ring-inset": true,
    "data-[is-active]:before:ring-offset-blue-100": true,
    "group-[]/optgroup:first:before:!rounded-none": true,
    "group-[]/optgroup:last:before:!rounded-none": true
  },
  "family:dropdown__selectedIcon": {
    flex: true,
    absolute: true,
    "items-center": true,
    "text-blue-600": true,
    "left-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "family:dropdown__option": {
    "ml-[1.5em]": true
  },
  "family:dropdown__loadMore": {
    "data-[is-active]:bg-green-main": true
  },
  "family:dropdown__loadMoreInner": {
    flex: true,
    "text-sm": true,
    "text-neutral-500": true,
    "p-2": true,
    "items-center": true,
    "justify-center": true,
    "[&>span]:mr-2": true,
    "cursor-pointer": true,
    "dark:text-neutral-200": true,
    "dark:hover:text-blue-500": true
  },
  "family:dropdown__selectionWrapper": {
    grow: true,
    flex: true,
    "items-center": true,
    "text-neutral-700": true
  },
  "family:dropdown__selection": {
    grow: true,
    "text-neutral-700": true,
    "group-data-[multiple]:p-2": true,
    "dark:text-neutral-300": true
  },
  "family:dropdown__tagsWrapper": {
    "w-full": true
  },
  "family:dropdown__tagWrapper": {
    "group/tag": true,
    rounded: true,
    "mr-1": true,
    "mb-1": true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true,
    "data-[active-selection=true]:ring-blue-500": true
  },
  "family:dropdown__tags": {
    "inline-flex": true,
    "flex-wrap": true,
    "items-center": true,
    "w-full": true,
    "-mb-1": true,
    "empty:mb-0": true
  },
  "family:dropdown__tag": {
    flex: true,
    "items-center": true,
    "cursor-default": true,
    rounded: true,
    "text-sm": true,
    "px-1.5": true,
    "py-0.5": true,
    "bg-blue-600": true,
    "text-white": true,
    "[&>[type=button]]:!w-[0.5em]": true,
    "[&>[type=button]]:aspect-[1/1]": true,
    "[&>[type=button]]:!text-inherit": true,
    "[&>[type=button]]:cursor-pointer": true,
    "group-data-[active-selection=true]/tag:bg-blue-400": true,
    "group-data-[active-selection=true]/tag:text-neutral-700": true
  },
  "family:dropdown__tagLabel": {
    "mr-1": true
  },
  "family:dropdown__emptyMessage": {
    flex: true,
    "items-center": true,
    "px-2": true,
    "py-1.5": true,
    "first:pt-2": true,
    "last:pb-2": true,
    "text-neutral-700": true,
    "text-sm": true,
    "aria-selected:text-white": true,
    "aria-selected:bg-blue-600": true
  },
  button__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "bg-blue-50": false,
    "hover:bg-green-main": false,
    "dark:text-blue-500": false,
    "dark:bg-transparent": false,
    "dark:hover:bg-blue-50/5": false
  },
  checkbox__decorator: {
    rounded: false
  },
  checkbox__decoratorIcon: {
    "max-w-[66.66%]": false
  },
  color__inner: {
    "!w-auto": false,
    "!p-1.5": false,
    "!inline-flex": false,
    "group-data-[prefix-icon]:border": false,
    "group-data-[prefix-icon]:border-neutral-400": false,
    "group-data-[suffix-icon]:border": false,
    "group-data-[suffix-icon]:border-neutral-400": false,
    "dark:group-data-[prefix-icon]:border-neutral-500": false,
    "dark:group-data-[suffix-icon]:border-neutral-500": false
  },
  color__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "!w-14": false,
    "bg-transparent": false,
    "cursor-pointer": false,
    rounded: false,
    "overflow-clip": false,
    "[&::-webkit-color-swatch-wrapper]:p-0": false,
    "[&::-webkit-color-swatch]:border-none": false,
    "[&::-moz-color-swatch]:border-none": false,
    "group-data-[prefix-icon]:mx-2": false,
    "group-data-[suffix-icon]:mx-2": false
  },
  color__prefixIcon: {
    flex: false,
    "items-center": false,
    "-ml-1": false,
    "mr-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "group-data-[prefix-icon]:m-1.5": false,
    "group-data-[prefix-icon]:mr-0": false
  },
  color__suffixIcon: {
    flex: false,
    "items-center": false,
    "-mr-1": false,
    "ml-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "group-data-[suffix-icon]:m-1.5": false,
    "group-data-[prefix-icon]:ml-0": false
  },
  date__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "focus:[&::-webkit-datetime-edit-day-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-month-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-year-field]:bg-green-main": false
  },
  "datetime-local__input": {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "focus:[&::-webkit-datetime-edit-day-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-month-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-year-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-hour-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-minute-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-ampm-field]:bg-green-main": false
  },
  file__fileList: {
    "group/list": false,
    peer: false,
    "w-full": false,
    "min-w-0": false,
    "data-[has-multiple]:mb-[1.25em]": false
  },
  file__fileItemIcon: {
    "h-[1em]": false,
    "w-[1em]": false,
    "mr-2": false,
    "shrink-0": false
  },
  file__fileItem: {
    flex: false,
    "min-w-0": false,
    "items-center": false,
    "text-neutral-700": false,
    "mb-1.5": false,
    "last:mb-0": false,
    "dark:text-neutral-300": false
  },
  file__fileName: {
    truncate: false,
    "min-w-0": false,
    "w-full": false,
    shrink: false,
    "leading-5": false,
    "group-data-[has-multiple]/list:text-sm": false
  },
  file__fileRemove: {
    "right-2": false,
    "ring-blue-500": false,
    rounded: false,
    "z-20": false,
    flex: false,
    "appearance-none": false,
    "items-center": false,
    "text-[0px]": false,
    "outline-none": false,
    "hover:!text-red-500": false,
    "focus-visible:ring-2": false,
    "group-data-[disabled]:pointer-events-none": false,
    "group-data-[disabled]:!text-neutral-500": false,
    "peer-data-[has-multiple]:absolute": false,
    "peer-data-[has-multiple]:bottom-[max(0.5em,8px)]": false,
    "peer-data-[has-multiple]:left-3": false,
    "peer-data-[has-multiple]:text-blue-600": false,
    "peer-data-[has-multiple]:text-xs": false,
    "peer-data-[has-multiple]:whitespace-nowrap": false,
    "group-data-[prefix-icon]:peer-data-[has-multiple]:left-2": false,
    "dark:hover:!text-red-400": false
  },
  file__fileRemoveIcon: {
    block: false,
    "text-base": false,
    "w-[0.75em]": false,
    relative: false,
    "z-10": false
  },
  file__inner: {
    relative: false,
    "cursor-pointer": false,
    "group-data-[has-multiple]:rounded": false
  },
  file__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "cursor-pointer": false,
    "text-transparent": false,
    absolute: false,
    "inset-0": false,
    "opacity-0": false,
    "z-10": false,
    "file:pointer-events-none": false,
    "file:w-0": false,
    "file:h-0": false,
    "file:overflow-hidden": false
  },
  file__noFiles: {
    flex: false,
    "w-full": false,
    "items-center": false,
    "text-neutral-400": false,
    "dark:text-neutral-500": false
  },
  file__noFilesIcon: {
    "w-[1em]": false,
    "mr-2": false
  },
  form__form: {
    "group/form": false
  },
  form__actions: {
    "": false
  },
  form__summaryInner: {
    "group/summary": false,
    border: false,
    "border-neutral-400": false,
    "bg-white": false,
    rounded: false,
    "py-2": false,
    "px-3": false,
    shadow: false,
    "dark:bg-transparent": false,
    "dark:border-neutral-500": false
  },
  form__summaryHeader: {
    "text-lg": false,
    "text-neutral-700": false,
    "font-bold": false,
    "mb-2": false,
    "dark:text-neutral-300": false
  },
  form__messages: {
    "": false
  },
  form__message: {
    "text-red-600": false,
    "mb-1.5": false,
    "text-xs": false,
    "dark:text-red-400": false,
    "group-[]/summary:text-sm": false
  },
  form__messageLink: {
    "group-[]/summary:outline-none": false,
    "group-[]/summary:focus-visible:ring-2": false,
    "group-[]/summary:ring-blue-600": false
  },
  month__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "focus:[&::-webkit-datetime-edit-month-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-year-field]:bg-green-main": false
  },
  radio__decorator: {
    "rounded-full": false
  },
  radio__decoratorIcon: {
    "max-w-[50%]": false
  },
  range__inner: {
    relative: false,
    "!border-none": false,
    "!ring-0": false,
    "!px-0": false,
    "!bg-transparent": false,
    "!shadow-none": false
  },
  range__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "group/input": false,
    "cursor-pointer": false,
    "[&::-webkit-slider-runnable-track]:bg-neutral-400/50": false,
    "[&::-webkit-slider-runnable-track]:h-[0.25em]": false,
    "[&::-webkit-slider-runnable-track]:rounded": false,
    "dark:[&::-webkit-slider-runnable-track]:bg-neutral-500/50": false,
    "[&::-webkit-slider-thumb]:appearance-none": false,
    "[&::-webkit-slider-thumb]:w-[0.85em]": false,
    "[&::-webkit-slider-thumb]:aspect-[1/1]": false,
    "[&::-webkit-slider-thumb]:bg-blue-600": false,
    "[&::-webkit-slider-thumb]:rounded-full": false,
    "[&::-webkit-slider-thumb]:relative": false,
    "[&::-webkit-slider-thumb]:top-1/2": false,
    "[&::-webkit-slider-thumb]:-translate-y-1/2": false,
    "[&::-webkit-slider-thumb]:group-data-[disabled]:bg-neutral-500": false,
    "[&::-webkit-slider-thumb]:group-data-[disabled]:!ring-neutral-300": false,
    "[&::-webkit-slider-thumb]:focus-visible:ring-2": false,
    "[&::-webkit-slider-thumb]:focus:!ring-blue-500": false,
    "[&::-webkit-slider-thumb]:focus:ring-offset-2": false,
    "[&::-webkit-slider-thumb]:shadow": false,
    "dark:[&::-webkit-slider-thumb]:group-data-[disabled]:!ring-neutral-600":
      true,
    "dark:[&::-webkit-slider-thumb]:focus:ring-offset-neutral-700": false,
    "[&::-moz-range-track]:bg-neutral-400/50": false,
    "[&::-moz-range-track]:h-[0.25]": false,
    "[&::-moz-range-track]:rounded": false,
    "dark:[&::-moz-range-track]:bg-neutral-500/50": false,
    "[&::-moz-range-thumb]:appearance-none": false,
    "[&::-moz-range-thumb]:border-none": false,
    "[&::-moz-range-thumb]:w-[0.85em]": false,
    "[&::-moz-range-thumb]:h-[0.85em]": false,
    "[&::-moz-range-thumb]:bg-blue-600": false,
    "[&::-moz-range-thumb]:rounded-full": false,
    "[&::-moz-range-thumb]:group-data-[disabled]:bg-neutral-500": false,
    "[&::-moz-range-thumb]:group-data-[disabled]:!ring-neutral-300": false,
    "[&::-moz-range-thumb]:focus-visible:ring-2": false,
    "[&::-moz-range-thumb]:focus:!ring-blue-500": false,
    "[&::-moz-range-thumb]:focus:ring-offset-2": false,
    "[&::-moz-range-thumb]:shadow": false,
    "dark:[&::-moz-range-thumb]:group-data-[disabled]:!ring-neutral-500": false,
    "dark:[&::-moz-range-thumb]:focus:ring-offset-neutral-700": false
  },
  select__wrapper: {
    "mb-1.5": false
  },
  select__inner: {
    relative: false,
    flex: false,
    "items-center": false,
    "bg-white": false,
    border: false,
    "border-neutral-400": false,
    rounded: false,
    "focus-within:ring-1": false,
    "focus-within:!ring-blue-500": false,
    "focus-within:!border-blue-500": false,
    "group-data-[invalid]:border-red-500": false,
    "group-data-[invalid]:ring-1": false,
    "group-data-[invalid]:ring-red-500": false,
    "group-data-[disabled]:bg-neutral-100": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    shadow: false,
    "group-[]/repeater:shadow-none": false,
    "group-[]/multistep:shadow-none": false,
    "group-data-[multiple]:rounded": false,
    "dark:bg-transparent": false,
    "dark:border-neutral-500": false,
    "dark:group-data-[disabled]:bg-neutral-800/5": false,
    "dark:group-data-[invalid]:border-red-500": false,
    "dark:group-data-[invalid]:ring-red-500": false
  },
  select__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    grow: false,
    "p-2": false,
    "py-2": false,
    "px-3": false,
    "pr-[2em]": false,
    "text-base": false,
    "text-neutral-700": false,
    "text-ellipsis": false,
    "min-w-0": false,
    "outline-none": false,
    "bg-transparent": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    "group-data-[prefix-icon]:!pl-0": false,
    "group-data-[suffix-icon]:!pr-0": false,
    "data-[placeholder]:text-neutral-400": false,
    "group-data-[multiple]:!p-0": false,
    "selection:bg-green-main": false,
    "dark:data-[placeholder]:text-neutral-400/50": false,
    "dark:text-neutral-300": false,
    "border-none": false,
    "focus:ring-0": false,
    "bg-none": false
  },
  select__selectIcon: {
    absolute: false,
    "w-[1em]": false,
    "text-neutral-700": false,
    "pointer-events-none": false,
    "right-2": false,
    "group-data-[suffix-icon]:mr-[1.5em]": false,
    "dark:text-neutral-300": false
  },
  select__optGroup: {
    "bg-white": false,
    "text-neutral-700": false,
    "group/optgroup": false,
    "group-data-[multiple]:px-1.5": false,
    "pt-1.5": false,
    "font-bold": false,
    "text-sm": false,
    "dark:bg-neutral-800": false,
    "dark:text-neutral-300": false
  },
  select__option: {
    "bg-white": false,
    "text-neutral-700": false,
    "group-data-[disabled]:opacity-50": false,
    "group-data-[disabled]:select-none": false,
    "group-data-[multiple]:checked:bg-green-main": false,
    "group-data-[multiple]:focus:bg-green-main": false,
    "group-data-[multiple]:text-sm": false,
    "group-data-[multiple]:outline-none": false,
    "group-data-[multiple]:border-none": false,
    "group-data-[multiple]:py-1.5": false,
    "group-data-[multiple]:px-2": false,
    "dark:bg-neutral-800": false,
    "dark:text-neutral-300": false,
    "dark:group-data-[multiple]:focus:bg-blue-800": false,
    "dark:group-data-[multiple]:checked:bg-blue-800": false
  },
  select__prefixIcon: {
    flex: false,
    "items-center": false,
    "mr-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "ml-2": false,
    "text-neutral-600": false,
    "dark:text-neutral-300": false
  },
  select__suffixIcon: {
    flex: false,
    "items-center": false,
    "ml-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "mr-2": false,
    "text-neutral-600": false,
    "dark:text-neutral-300": false
  },
  submit__outer: {
    group: false,
    "max-w-[20em]": false,
    "min-w-0": false,
    grow: false,
    "mb-4": false,
    "data-[disabled]:select-none": false,
    "text-base": false,
    "data-[disabled]:opacity-100": false
  },
  submit__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "bg-blue-600": false,
    "!text-white": false,
    "active:text-blue-100": false,
    "active:bg-blue-700": false,
    "hover:bg-blue-700": false,
    "disabled:border-neutral-400": false,
    "disabled:bg-neutral-400": false,
    "disabled:text-neutral-100": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    "dark:disabled:border-neutral-100": false,
    "dark:disabled:bg-neutral-500": false,
    "dark:disabled:text-neutral-200": false,
    "dark:text-white": false,
    "dark:ring-offset-blue-500": false,
    "before:transition-all": false,
    "group-data-[loading=true]/form:before:content['']": false,
    "group-data-[loading=true]/form:before:block": false,
    "group-data-[loading=true]/form:before:animate-spin": false,
    "group-data-[loading=true]/form:before:w-5": false,
    "group-data-[loading=true]/form:before:h-5": false,
    "group-data-[loading=true]/form:before:rounded-full": false,
    "group-data-[loading=true]/form:before:mr-3": false,
    "group-data-[loading=true]/form:before:-ml-1.5": false,
    "group-data-[loading=true]/form:before:border-2": false,
    "group-data-[loading=true]/form:before:border-solid": false,
    "group-data-[loading=true]/form:before:border-white": false,
    "group-data-[loading=true]/form:before:border-r-transparent": false
  },
  submit__prefixIcon: {
    flex: false,
    "items-center": false,
    "-ml-1": false,
    "mr-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "text-neutral-100": false
  },
  submit__suffixIcon: {
    flex: false,
    "items-center": false,
    "-mr-1": false,
    "ml-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "text-neutral-100": false
  },
  textarea__inner: {
    flex: false,
    "items-center": false,
    "mb-1.5": false,
    "bg-white": false,
    border: false,
    "border-neutral-400": false,
    rounded: false,
    "focus-within:ring-1": false,
    "focus-within:!ring-blue-500": false,
    "focus-within:!border-blue-500": false,
    "group-data-[invalid]:border-red-500": false,
    "group-data-[invalid]:ring-1": false,
    "group-data-[invalid]:ring-red-500": false,
    "group-data-[disabled]:bg-neutral-100": false,
    shadow: false,
    "group-[]/repeater:shadow-none": false,
    "group-[]/multistep:shadow-none": false,
    "dark:border-neutral-500": false,
    "dark:group-data-[disabled]:bg-neutral-800/5": false,
    "dark:group-data-[invalid]:border-red-500": false,
    "dark:group-data-[invalid]:ring-red-500": false,
    "dark:bg-transparent": false
  },
  textarea__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "text-base": false,
    "h-24": false,
    "text-neutral-700": false,
    "min-w-0": false,
    grow: false,
    shrink: false,
    "!py-2": false,
    "!px-3": false,
    "outline-none": false,
    "bg-transparent": false,
    "selection:bg-green-main": false,
    "placeholder:text-neutral-400": false,
    "group-data-[disabled]:!cursor-not-allowed": false,
    "dark:placeholder-neutral-400/50": false,
    "dark:text-neutral-300": false,
    "p-0": false,
    "border-none": false,
    "focus:ring-0": false
  },
  textarea__prefixIcon: {
    flex: false,
    "items-center": false,
    "-ml-1": false,
    "mr-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "!ml-2": false,
    "!mr-0": false,
    "text-neutral-600": false,
    "dark:text-neutral-300": false
  },
  textarea__suffixIcon: {
    flex: false,
    "items-center": false,
    "-mr-1": false,
    "ml-2": false,
    "text-base": false,
    "h-[1em]": false,
    "w-[1em]": false,
    "shrink-0": false,
    "[&>svg]:w-full": false,
    "!mr-2": false,
    "!ml-0": false,
    "text-neutral-600": false,
    "dark:text-neutral-300": false
  },
  time__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "focus:[&::-webkit-datetime-edit-hour-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-minute-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-ampm-field]:bg-green-main": false
  },
  week__input: {
    "appearance-none": false,
    "[color-scheme:light]": false,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": false,
    "selection:text-neutral-700": false,
    "group-data-[has-overlay]:selection:!text-transparent": false,
    "focus:[&::-webkit-datetime-edit-week-field]:bg-green-main": false,
    "focus:[&::-webkit-datetime-edit-year-field]:bg-green-main": false
  },
  autocomplete__selections: {
    flex: true,
    absolute: true,
    "inset-0": true,
    "group-data-[multiple]:static": true,
    "group-data-[multiple]:block": true,
    "group-data-[empty]:hidden": true,
    "group-data-[multiple]:mt-1.5": true
  },
  autocomplete__inner: {
    "hover:border-black-800": true
  },
  autocomplete__input: {
    "py-2 text-sm font-normal leading-snug": true
  },
  autocomplete__outer: {
    "mt-1": true
  },
  autocomplete__selectionWrapper: {
    "bg-neutral-100": true,
    rounded: true,
    "group-data-[multiple]:border": true,
    "group-data-[multiple]:border-neutral-300": true,
    "group-data-[multiple]:mb-1.5": true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true,
    "data-[active-selection=true]:ring-blue-500": true,
    "dark:bg-neutral-600": true,
    "dark:group-data-[multiple]:border-neutral-500": true,
    "[&.formkit-dropZone]:opacity-25": true,
    "[&.formkit-touchDropZone]:opacity-25": true,
    "[&.formkit-touchDragging]:!flex": true,
    "[&.formkit-longTouch]:opacity-25": true
  },
  autocomplete__selection: {
    rounded: true,
    just: true,
    "pl-2": true,
    "[&>*]:ml-0": true,
    "dark:text-neutral-200": false
  },
  colorpicker__outer: {
    group: true,
    "max-w-[20em]": true,
    "min-w-0": true,
    grow: true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "text-base": true,
    "data-[disabled]:cursor-not-allowed": true,
    "data-[disabled]:pointer-events-none": true
  },
  colorpicker__help: {
    "text-neutral-500": true,
    "text-xs": true,
    "dark:text-neutral-400": false,
    "group-data-[inline]:-mt-1": true,
    "group-data-[inline]:mb-2": true
  },
  colorpicker__inner: {
    relative: true,
    "inline-flex": true,
    "!w-auto": true,
    "pl-2": true,
    "group-data-[inline]:border-none": true,
    "group-data-[inline]:shadow-none": true,
    "group-data-[inline]:p-0": true,
    "group-data-[inline]:bg-transparent": true,
    "group-data-[inline]:outline-none": true,
    "group-data-[inline]:!ring-0": true,
    "group-data-[inline]:!w-full": true,
    "group-data-[inline]:rounded": true
  },
  colorpicker__swatchPreview: {
    "w-full": true,
    flex: true,
    "justify-start": true,
    "items-center": true,
    rounded: true,
    "text-sm": true,
    "cursor-pointer": true,
    "outline-none": true
  },
  colorpicker__canvasSwatchPreviewWrapper: {
    relative: true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:rounded": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:z-[2]": true
  },
  colorpicker__canvasSwatchPreview: {
    "text-base": true,
    rounded: true,
    "aspect-[1/1]": true,
    "shrink-0": true,
    grow: true,
    "!w-[1.5em]": true
  },
  colorpicker__valueString: {
    "text-base": true,
    "text-neutral-700": true,
    "selection:bg-green-main": true,
    "font-mono": true,
    "inline-block": true,
    "ml-2": true,
    "mr-1.5": true,
    "dark:text-neutral-300": false,
    "dark:selection:text-neutral-700": true
  },
  colorpicker__panel: {
    absolute: true,
    "left-0": true,
    "top-full": true,
    "z-[99]": true,
    flex: true,
    "w-[100vw]": true,
    "max-w-[18.5em]": true,
    "touch-manipulation": true,
    "flex-col": true,
    rounded: true,
    border: true,
    "bg-white": true,
    "p-2": true,
    "shadow-md": true,
    "group-data-[inline]:static": true,
    "group-data-[inline]:max-w-none": true,
    "border-neutral-400": true,
    "group-data-[inline]:z-auto": true,
    "group-data-[inline]:w-full": true,
    "group-data-[inline]:shadow": true,
    "group-data-[inline]:group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[inline]:group-data-[disabled]:!pointer-events-none": true,
    "group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)]:w-auto":
      true,
    "group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)_[id^=swatches]>div]:w-[1.5em]":
      true,
    "dark:bg-neutral-800": true,
    "dark:border-neutral-500": true,
    "dark:group-data-[inline]:bg-transparent": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none":
      true
  },
  colorpicker__panelClose: {
    flex: true,
    "justify-end": true,
    "items-center": true,
    "text-neutral-600": true,
    "mb-1.5": true,
    "-mt-1": true,
    "border-none": true,
    "bg-none": true,
    "border-b": true,
    "border-neutral-300": true,
    "w-[calc(100%+1rem)]": true,
    "-ml-2": true,
    "pt-0": true,
    "pr-2": true,
    "pb-1.5": true,
    "pl-2": true,
    "dark:border-neutral-600": true
  },
  colorpicker__closeIcon: {
    "w-[2rem]": true,
    "aspect-[1/1]": true,
    "p-1": true,
    rounded: true,
    border: true,
    "[&>svg]:w-full": true,
    "[&>svg]:aspect-[1/1]": true,
    "[&>svg]:max-w-none": true,
    "[&>svg]:max-h-none": true
  },
  colorpicker__controlGroup: {
    grid: true,
    "[grid-template-areas:'a_a_a'_'b_c_e'_'b_d_e']": true,
    "mb-2": true
  },
  colorpicker__LS: {
    "[grid-area:a]": true,
    relative: true,
    "mb-2": true
  },
  colorpicker__canvas: {
    block: true,
    "w-full": true
  },
  colorpicker__canvasLS: {
    "aspect-[2/1]": true,
    "cursor-pointer": true,
    "rounded-none": true
  },
  colorpicker__canvasHue: {
    "rounded-none": true
  },
  colorpicker__canvasAlpha: {
    "rounded-none": true
  },
  colorpicker__preview: {
    rounded: true,
    "after:rounded": true,
    relative: true,
    "inline-flex": true,
    "aspect-[1/1]": true,
    "overflow-hidden": true,
    "[grid-area:b]": true,
    "mr-2": true,
    "after:absolute": true,
    "after:left-0": true,
    "after:top-0": true,
    "after:h-full": true,
    "after:w-full": true,
    "after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "after:content-['']": true,
    "w-[2em]": true,
    "dark:after:shadow-[inset_0_0_0_1px_rgba(255,255,255,0.25)]": true
  },
  colorpicker__hue: {
    "[grid-area:c]": true,
    relative: true,
    "inline-flex": true,
    "h-3/4": true
  },
  colorpicker__alpha: {
    "[grid-area:d]": true,
    relative: true,
    "inline-flex": true,
    "h-3/4": true
  },
  colorpicker__eyeDropper: {
    "[grid-area:e]": true,
    "w-[2em]": true,
    "ml-2": true,
    "inline-flex": true,
    "self-center": true,
    "justify-center": true,
    "justify-self-center": true,
    "aspect-[1/1]": true,
    rounded: true,
    border: true,
    "border-neutral-300": true,
    "cursor-pointer": true,
    "content-center": true,
    "items-center": true,
    "text-neutral-600": true,
    "dark:border-neutral-600": true
  },
  colorpicker__eyeDropperIcon: {
    "w-auto": true,
    "[&>svg]:w-[1em]": true,
    "dark:text-neutral-400": false
  },
  colorpicker__control: {
    absolute: true,
    "bg-white": true,
    "shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true,
    "-translate-y-1/2": true,
    "-translate-x-1/2": true,
    "pointer-events-none": true,
    "data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]":
      true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-offset-2": true,
    "focus-visible:ring-blue-500": true
  },
  colorpicker__controlLS: {
    "w-[10px]": true,
    "h-[10px]": true,
    "rounded-full": true
  },
  colorpicker__controlHue: {
    "w-[4px]": true,
    "h-[calc(100%-2px)]": true,
    "top-1/2": true,
    rounded: true
  },
  colorpicker__controlAlpha: {
    "w-[4px]": true,
    "h-[calc(100%-2px)]": true,
    "top-1/2": true,
    rounded: true
  },
  colorpicker__formatField: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    grow: true
  },
  colorpicker__colorField: {
    "bg-transparent": true,
    "text-neutral-700": true,
    border: true,
    "border-neutral-300": true,
    "dark:border-neutral-600": true,
    "dark:text-neutral-300": false,
    "dark:selection:text-neutral-700": true
  },
  colorpicker__colorInputGroup: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    grow: true
  },
  colorpicker__fieldGroup: {
    flex: true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "w-full": true,
    "mr-1": true,
    "[&>input]:p-1": true,
    "[&>input]:text-sm": true,
    "[&>input]:text-neutral-700": true,
    "[&>input]:selection:bg-green-main": true,
    "[&>input]:m-0": true,
    "[&>input]:grow": true,
    "[&>input]:shrink": true,
    "[&>input]:w-full": true,
    "[&>input]:border": true,
    "[&>input]:border-neutral-300": true,
    "[&>input]:rounded": true,
    "[&>input]:text-center": true,
    "[&>input]:appearance-none": true,
    "[&>input::-webkit-outer-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:m-0": true,
    "[&>input:focus]:outline-none": true,
    "[&>input:focus]:ring-1": true,
    "[&>input:focus]:ring-blue-600": true,
    "[&>input:focus]:border-blue-600": true,
    "max-[431px]:[&>input]:text-base": true
  },
  colorpicker__fieldLabel: {
    "text-xs": true,
    "text-neutral-500": true,
    "mt-1.5": true,
    "dark:text-neutral-400": false
  },
  colorpicker__formatSwitcher: {
    flex: true,
    "justify-end": true,
    "self-start": true,
    uppercase: true,
    "shrink-0": true,
    "p-1": true,
    "mt-0.5": true,
    "text-neutral-600": true,
    rounded: true,
    "select-none": true,
    "dark:text-neutral-400": false
  },
  colorpicker__switchIcon: {
    "[&>svg]:w-3": true
  },
  colorpicker__swatches: {
    "inline-flex": true,
    "flex-wrap": true,
    "w-full": true,
    "justify-self-center": true,
    "min-w-0": true,
    "mx-auto": true,
    "px-[1px]": true,
    "pt-2": true,
    "pb-2": true,
    "mt-2": true,
    "-mb-2": true,
    "border-t": true,
    "border-neutral-300": true,
    "overflow-auto": true,
    "max-h-[200px]": true,
    "select-none": true,
    "first:-mt-[3px]": true,
    "first:last:-mb-[3px]": true,
    "first:last:pb-[2px]": true,
    "first:pt-px": true,
    "first:border-t-0": true,
    "dark:border-neutral-600": true
  },
  colorpicker__swatchGroup: {
    flex: true,
    "flex-wrap": true,
    "w-full": true,
    "mb-2": true,
    "last:mb-0": true
  },
  colorpicker__swatchGroupLabel: {
    "ml-1": true,
    block: true,
    "w-full": true,
    "text-sm": true,
    "text-neutral-500": true,
    "dark:text-neutral-400": false
  },
  colorpicker__swatch: {
    relative: true,
    "text-base": true,
    "w-[calc((100%/10)-0.5em)]": true,
    "max-w-[22px]": true,
    "m-[0.16em]": true,
    "cursor-pointer": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:rounded": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:pointer-events-none": true,
    "before:z-[2]": true,
    "dark:before:shadow-[inset_0_0_0_1px_rgba(255,255,255,0.25)]": true,
    "data-[active=true]:after:content-['']": true,
    "data-[active=true]:after:block": true,
    "data-[active=true]:after:absolute": true,
    "data-[active=true]:after:w-1.5": true,
    "data-[active=true]:after:h-1.5": true,
    "data-[active=true]:after:top-1/2": true,
    "data-[active=true]:after:left-1/2": true,
    "data-[active=true]:after:pointer-events-none": true,
    "data-[active=true]:after:rounded-full": true,
    "data-[active=true]:after:-translate-x-1/2": true,
    "data-[active=true]:after:-translate-y-1/2": true,
    "data-[active=true]:after:bg-white": true,
    "data-[active=true]:after:z-[2]": true,
    "data-[active=true]:after:ring-1": true,
    "data-[active=true]:after:ring-[rgba(0,0,0,0.33)]": true,
    "[&>canvas]:block": true,
    "[&>canvas]:w-full": true,
    "[&>canvas]:aspect-[1/1]": true,
    "[&>canvas]:rounded": true,
    "[&>canvas:focus-visible]:outline-none": true,
    "[&>canvas:focus-visible]:ring-2": true,
    "[&>canvas:focus-visible]:ring-blue-500": true,
    "[&>canvas:focus-visible]:ring-offset-2": true,
    "[&>canvas:focus-visible]:ring-offset-white": true,
    "dark:[&>canvas:focus-visible]:ring-offset-neutral-700": true
  },
  datepicker__inner: {
    relative: true
  },
  datepicker__removeSelection: {
    "self-stretch": true,
    "text-base": true,
    flex: true,
    "items-center": true,
    "ml-1": true,
    "mr-2": true,
    "text-neutral-700": true,
    "hover:text-red-400": true,
    "z-10": true,
    "dark:text-neutral-300": false
  },
  datepicker__clearIcon: {
    "[&>svg]:w-[0.75em]": true
  },
  datepicker__panelWrapper: {
    "group/panel": true,
    absolute: true,
    "min-w-[20em]": true,
    "top-[calc(100%_+_0.5em)]": true,
    "left-0": true,
    "shadow-[0_0_1.25em_rgba(0,0,0,.25)]": true,
    rounded: true,
    "p-4": true,
    "bg-white": true,
    "z-10": true,
    "dark:bg-neutral-800": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:w-full":
      true
  },
  datepicker__panelHeader: {
    grid: true,
    "grid-cols-[2.5em_1fr_2.5em]": true,
    "justify-center": true,
    "items-center": true,
    "border-b-2": true,
    "border-neutral-300": true,
    "mb-2": true,
    "pb-2.5": true,
    "dark:border-neutral-600": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:grid-cols-[2.5em_1fr_2.5em_2.5em]":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=time]/panel:grid-cols-[2.5em_1fr_2.5em]":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=month]/panel:grid-cols-[2.5em_1fr_2.5em]":
      true
  },
  datepicker__panelClose: {
    "aspect-[1/1]": true,
    border: true,
    "border-neutral-300": true,
    rounded: true,
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-neutral-700": true,
    "[&_svg]:w-[1.25em]": true,
    "dark:text-neutral-300": false,
    "dark:border-neutral-600": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=time]/panel:col-start-3":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=month]/panel:col-start-3":
      true
  },
  datepicker__panel: {
    flex: true,
    "justify-center": true
  },
  datepicker__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "placeholder:text-neutral-400": true
  },
  datepicker__monthsHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true
  },
  datepicker__timeHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true
  },
  datepicker__months: {
    grid: true,
    "grid-cols-3": true,
    "w-full": true
  },
  datepicker__month: {
    "m-1.5": true,
    "p-1.5": true,
    "text-center": true,
    "text-neutral-700": true,
    rounded: true,
    "bg-neutral-200": true,
    "aria-selected:!bg-blue-600": true,
    "aria-selected:!text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-blue-600": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "focus:text-neutral-700": true,
    "data-[is-extra=true]:opacity-25": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:cursor-default": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "dark:bg-neutral-700": true,
    "dark:text-neutral-300": false
  },
  datepicker__yearsHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-neutral-700": true,
    "col-start-2": true,
    "col-end-2": true,
    "dark:text-neutral-300": false
  },
  datepicker__years: {
    grid: true,
    "grid-cols-5": true,
    "w-full": true
  },
  datepicker__year: {
    "text-base": true,
    "text-center": true,
    "text-neutral-700": true,
    "items-center": true,
    "m-1.5": true,
    "p-1.5": true,
    rounded: true,
    "bg-neutral-200": true,
    "aria-selected:!bg-blue-600": true,
    "aria-selected:!text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-blue-600": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "data-[is-extra=true]:opacity-25": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:cursor-default": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "dark:bg-neutral-700": true,
    "dark:text-neutral-300": false
  },
  datepicker__weekDays: {
    grid: true,
    "grid-cols-7": true
  },
  datepicker__weekDay: {
    "w-[2.25em]": true,
    "text-neutral-700": true,
    "m-1.5": true,
    rounded: true,
    "font-medium": true,
    lowercase: true,
    "dark:text-neutral-500": true
  },
  datepicker__calendarWeeks: {
    "": true
  },
  datepicker__week: {
    grid: true,
    "grid-cols-7": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:cursor-default": true,
    "group-data-[disabled=true]:pointer-events-none": true
  },
  datepicker__dayCell: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-center": true,
    "text-neutral-700": true,
    "w-[2.25em]": true,
    "h-[2.25em]": true,
    "m-1": true,
    "p-2": true,
    rounded: true,
    "bg-neutral-200": true,
    "aria-selected:bg-blue-600": true,
    "aria-selected:text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-blue-600": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "data-[is-extra=true]:opacity-25": true,
    "data-[disabled=true]:opacity-50": true,
    "data-[disabled=true]:cursor-default": true,
    "data-[disabled=true]:pointer-events-none": true,
    "dark:bg-neutral-600": true,
    "dark:text-neutral-300": false,
    "dark:aria-selected:bg-blue-600": true,
    "dark:aria-selected:text-white": true,
    "dark:focus:outline-blue-600": true,
    "dark:focus:bg-neutral-200": true,
    "dark:focus:text-neutral-700": true
  },
  datepicker__timeInput: {
    "w-full": true,
    "border-2": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "text-neutral-700": true,
    "border-neutral-300": true,
    rounded: true,
    "p-1.5": true,
    "my-2.5": true,
    "focus-visible:outline-blue-600": true,
    "dark:text-neutral-300": false,
    "dark:bg-transparent": true,
    "dark:border-neutral-600": true
  },
  datepicker__daysHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true
  },
  datepicker__prev: {
    "mr-auto": true,
    "px-2.5": true,
    "py-0.5": true,
    "hover:bg-neutral-100": true,
    rounded: true,
    "col-start-1": true,
    "col-end-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-blue-500": true,
    "focus-visible:ring-offset-2": true
  },
  datepicker__prevLabel: {
    hidden: true
  },
  datepicker__prevIcon: {
    flex: true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-neutral-700": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-300": false
  },
  datepicker__dayButton: {
    "appearance-none": true,
    "text-neutral-700": true,
    "cursor-pointer": true,
    "px-2.5": true,
    "py-0.5": true,
    "border-2": true,
    "border-neutral-300": true,
    rounded: true,
    "mx-1": true,
    "hover:border-blue-600": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-blue-500": true,
    "focus-visible:ring-offset-2": true,
    "dark:text-neutral-300": false,
    "dark:border-neutral-600": true,
    "dark:hover:border-blue-500": true
  },
  datepicker__monthButton: {
    "appearance-none": true,
    "text-neutral-700": true,
    "cursor-pointer": true,
    "px-2.5": true,
    "py-0.5": true,
    "border-2": true,
    "border-neutral-300": true,
    rounded: true,
    "mx-1": true,
    "hover:border-blue-600": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-blue-500": true,
    "focus-visible:ring-offset-2": true,
    "dark:text-neutral-300": false,
    "dark:border-neutral-600": true,
    "dark:hover:border-blue-500": true
  },
  datepicker__yearButton: {
    "appearance-none": true,
    "text-neutral-700": true,
    "cursor-pointer": true,
    "px-2.5": true,
    "py-0.5": true,
    "border-2": true,
    "border-neutral-300": true,
    rounded: true,
    "mx-1": true,
    "hover:border-blue-600": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-blue-500": true,
    "focus-visible:ring-offset-2": true,
    "dark:text-neutral-300": false,
    "dark:border-neutral-600": true,
    "dark:hover:border-blue-500": true
  },
  datepicker__next: {
    "ml-auto": true,
    "px-2.5": true,
    "py-0.5": true,
    rounded: true,
    "hover:bg-neutral-100": true,
    "hover:rounded": true,
    "col-start-3": true,
    "col-end-3": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-blue-500": true,
    "focus-visible:ring-offset-2": true
  },
  datepicker__nextLabel: {
    hidden: true
  },
  datepicker__nextIcon: {
    flex: true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-neutral-700": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-300": false
  },
  datepicker__openButton: {
    "appearance-none": true,
    "border-0": true,
    "bg-transparent": true,
    flex: true,
    "p-0": true,
    "self-stretch": true,
    "cursor-pointer": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-blue-500": true,
    "focus-visible:ring-offset-2": true,
    "focus-visible:rounded": true
  },
  datepicker__calendarIcon: {
    "text-neutral-600": true,
    "focus-visible:text-blue-600": true,
    flex: true,
    "w-[1em]": true,
    "grow-0": true,
    "shrink-0": true,
    "self-stretch": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-h-[1em]": true,
    "[&>svg]:max-w-[1em]": true
  },
  dropdown__placeholder: {
    "text-neutral-400": true,
    grow: true,
    "dark:text-neutral-400/50": true
  },
  dropdown__selector: {
    flex: true,
    grow: true,
    "justify-between": true,
    "w-full": true,
    "py-[0.5rem]": true,
    "pl-3": true,
    "pr-0": true,
    "z-10": true,
    "text-base": true,
    "text-neutral-700": true,
    "text-left": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "data-[placeholder]:text-neutral-400": true,
    "selection:bg-green-main": true,
    "dark:data-[placeholder]:text-neutral-400/50": true,
    "dark:text-neutral-300": false
  },
  dropdown__inner: {
    "hover:border-black-800": true,
    "focus:border-green-70": true,
    "focus-within:border-green-70": true
  },
  dropdown__selectIcon: {
    "shrink-0": true
  },
  dropdown__selectionsWrapper: {
    "w-[85%]": true,
    "overflow-hidden": true
  },
  dropdown__selection: {
    "[&>*]:ml-0": true
  },
  dropdown__selections: {
    "inline-flex": true,
    "items-center": true
  },
  dropdown__selectionsItem: {
    "whitespace-nowrap": true,
    "mr-1": true
  },
  dropdown__tagWrapper: {
    "[&.formkit-dropZone_.formkit-tag]:opacity-25": true,
    "[&.formkit-touchDropZone_.formkit-tag]:opacity-25": true
  },
  dropdown__truncationCount: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "h-[1.5em]": true,
    rounded: true,
    "bg-neutral-400": true,
    "text-white": true,
    "whitespace-nowrap": true,
    "text-[11px]": true,
    "[line-height:1em]": true,
    "tracking-tighter": true,
    "leading-0": true,
    "py-1": true,
    "px-1": true,
    "shrink-0": true,
    "my-auto": true
  },
  mask__inner: {
    relative: true
  },
  mask__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "group-data-[has-overlay]:!caret-neutral-700": true,
    "dark:group-data-[has-overlay]:!caret-neutral-300": true
  },
  rating__inner: {
    "text-neutral-300": true
  },
  rating__itemsWrapper: {
    relative: true,
    "inline-flex": true,
    "focus:border-blue-600": true
  },
  rating__onItemRow: {
    "h-full": true,
    "w-full": true
  },
  rating__offItemRow: {
    "h-full": true,
    "w-full": true
  },
  rating__onItemWrapper: {
    "[&>*]:w-full": true,
    "[&>*]:h-full": true,
    "w-full": true,
    "h-full": true,
    "text-yellow-400": true
  },
  rating__offItemWrapper: {
    "text-neutral-400": true,
    "w-full": true,
    "h-full": true,
    "[&>*]:w-full": true,
    "[&>*]:h-full": true,
    "dark:text-neutral-500": true
  },
  rating__ratingItem: {
    relative: true,
    "focus-within:outline": true,
    "focus-within:outline-blue-600": true,
    "w-[1.5em]": true,
    "h-[1.5em]": true
  },
  rating__itemLabelInner: {
    "h-px": true,
    "w-px": true,
    "overflow-hidden": true,
    absolute: true,
    "white-space-nowrap": true
  },
  rating__itemLabel: {
    absolute: true,
    "h-full": true
  },
  rating__ratingIcon: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    flex: true
  },
  rating__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "outline-none": true
  },
  rating__messages: {
    "mt-1.5": true
  },
  repeater__outer: {
    "min-w-0": true,
    grow: true,
    "mb-4": true,
    "text-base": true,
    "group/repeater": true,
    "max-w-full": true
  },
  repeater__fieldset: {
    "min-w-0": true
  },
  repeater__legend: {
    block: true,
    "text-neutral-700": true,
    "text-sm": true,
    "font-bold": true,
    "dark:text-neutral-300": false,
    "mb-2": true
  },
  repeater__content: {
    "min-w-0": true,
    grow: true,
    "p-5": true,
    flex: true,
    "flex-col": true,
    "align-center": true,
    "[&>div[data-type]]:max-w-none": true,
    "[&>div[data-type]:last-child]:mb-0": true
  },
  repeater__addButton: {
    "!mb-0": true,
    "group-data-[disabled]/repeater:pointer-events-none": true,
    "group-data-[disabled]/repeater:opacity-50": true,
    "group-data-[disabled]/repeater:grayscale": true
  },
  repeater__controlLabel: {
    absolute: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true
  },
  repeater__controls: {
    flex: true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "bg-neutral-50": true,
    "p-2": true,
    "[&>li]:aspect-[1/1]": true,
    "dark:bg-neutral-800": true,
    rounded: true,
    "rounded-tl-none": true,
    "rounded-bl-none": true
  },
  repeater__downControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-1.5": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-neutral-500": true,
    "hover:text-blue-600": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-neutral-500": true,
    "dark:text-neutral-300": false,
    "dark:disabled:!text-neutral-300": true,
    "dark:hover:text-blue-500": true
  },
  repeater__upControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-1.5": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-neutral-500": true,
    "hover:text-blue-600": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-neutral-500": true,
    "dark:text-neutral-300": false,
    "dark:disabled:!text-neutral-300": true,
    "dark:hover:text-blue-500": true
  },
  repeater__removeControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-1.5": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-neutral-500": true,
    "hover:text-blue-600": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-neutral-500": true,
    "dark:text-neutral-300": false,
    "dark:disabled:!text-neutral-300": true,
    "dark:hover:text-blue-500": true
  },
  repeater__insertControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-1.5": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-neutral-500": true,
    "hover:text-blue-600": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-neutral-500": true,
    "dark:text-neutral-300": false,
    "dark:disabled:!text-neutral-300": true,
    "dark:hover:text-blue-500": true
  },
  repeater__help: {
    "text-neutral-500": true,
    "text-xs": true,
    "dark:text-neutral-400": false,
    "mb-2": true,
    "-mt-1": true
  },
  repeater__item: {
    flex: true,
    relative: true,
    "w-full": true,
    "mb-2": true,
    "bg-white": true,
    border: true,
    "border-neutral-300": true,
    rounded: true,
    shadow: true,
    "dark:border-neutral-600": true,
    "dark:bg-transparent": true,
    "[&.formkit-dropZone]:opacity-30": true,
    "[&.formkit-dropZone]:pointer-events-none": true,
    "[&.formkit-dropZone]:blur-[2px]": true
  },
  repeater__dragHandleWrapper: {
    relative: true,
    "w-8": true,
    "bg-neutral-50": true,
    rounded: true,
    "rounded-tr-none": true,
    "rounded-br-none": true,
    "dark:bg-neutral-800": true
  },
  repeater__dragHandle: {
    "w-full": true,
    "h-full": true,
    flex: true,
    absolute: true,
    "top-0": true,
    "left-0": true,
    "cursor-grab": true,
    "active:cursor-grabbing": true
  },
  repeater__dragHandleIcon: {
    "w-2": true,
    "m-auto": true,
    "text-neutral-500": true,
    "dark:text-neutral-400": false,
    "[&>svg>path]:fill-current": true
  },
  repeater__moveDownIcon: {
    block: true,
    "w-[0.75em]": true,
    "aspect-[1/1]": true
  },
  repeater__moveUpIcon: {
    block: true,
    "w-[0.75em]": true,
    "aspect-[1/1]": true
  },
  repeater__removeIcon: {
    block: true,
    "w-[1.25em]": true,
    "aspect-[1/1]": true
  },
  repeater__addIcon: {
    block: true,
    "w-[1.25em]": true,
    "aspect-[1/1]": true
  },
  slider__outer: {
    group: true,
    "max-w-[20em]": true,
    "min-w-0": true,
    grow: true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "text-base": true,
    "data-[disabled]:pointer-events-none": true
  },
  slider__help: {
    "text-neutral-500": true,
    "text-xs": true,
    "dark:text-neutral-400": false,
    "-mt-0.5": true,
    "mb-1.5": true
  },
  slider__sliderInner: {
    flex: true,
    "items-center": true,
    "[&>[data-type=number]]:mb-0": true,
    "[&>[data-type=number]]:ml-2.5": true,
    "[&>[data-type=number]]:shrink": true,
    "[&>[data-type=number]]:grow-0": true,
    "[&[data-has-mark-labels=true]_[id^=track]]:mb-5": true
  },
  slider__track: {
    grow: true,
    relative: true,
    "z-20": true,
    "py-2.5": true,
    "select-none": true
  },
  slider__trackWrapper: {
    "px-[2px]": true,
    "rounded-full": true,
    "bg-neutral-300": true,
    "dark:bg-neutral-500": true
  },
  slider__trackInner: {
    "h-1.5": true,
    "mx-0.5": true,
    relative: true
  },
  slider__prefixIcon: {
    flex: true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-neutral-600": true,
    "dark:text-neutral-300": false
  },
  slider__suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-neutral-600": true,
    "dark:text-neutral-300": false
  },
  slider__fill: {
    "h-full": true,
    "rounded-full": true,
    absolute: true,
    "top-0": true,
    "-mx-1": true,
    "bg-blue-600": true,
    "group-data-[disabled]:bg-neutral-500": true
  },
  slider__marks: {
    absolute: true,
    "pointer-events-none": true,
    "inset-0": true
  },
  slider__mark: {
    absolute: true,
    "top-1/2": true,
    "w-[3px]": true,
    "h-[3px]": true,
    "rounded-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "bg-neutral-400": true,
    "data-[active=true]:bg-white": true
  },
  slider__markLabel: {
    absolute: true,
    "top-[calc(100%+0.5em)]": true,
    "left-1/2": true,
    "text-neutral-400": true,
    "text-xs": true,
    "-translate-x-1/2": true
  },
  slider__handles: {
    "m-0": true,
    "p-0": true,
    "list-none": true
  },
  slider__handle: {
    group: true,
    "select-none": true,
    "w-4": true,
    "h-4": true,
    "rounded-full": true,
    "bg-white": true,
    absolute: true,
    "top-1/2": true,
    "left-0": true,
    "z-30": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.5)]":
      true,
    "focus-visible:outline-0": true,
    "focus-visible:ring-2": true,
    "ring-blue-600": true,
    "data-[is-target=true]:z-20": true,
    "dark:bg-neutral-200": true
  },
  slider__tooltip: {
    absolute: true,
    "bottom-full": true,
    "left-1/2": true,
    "-translate-x-1/2": true,
    "-translate-y-[4px]": true,
    "bg-blue-600": true,
    "text-white": true,
    "py-1": true,
    "px-1.5": true,
    "text-xs": true,
    "leading-none": true,
    "whitespace-nowrap": true,
    rounded: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "transition-opacity": true,
    'after:content-[""]': true,
    "after:absolute": true,
    "after:top-full": true,
    "after:left-1/2": true,
    "after:-translate-x-1/2": true,
    "after:-translate-y-[1px]": true,
    "after:border-4": true,
    "after:border-transparent": true,
    "after:border-t-blue-600": true,
    "group-hover:opacity-100": true,
    "group-focus-visible:opacity-100": true,
    "group-data-[show-tooltip=true]:opacity-100": true
  },
  slider__linkedValues: {
    flex: true,
    "items-start": true,
    "justify-between": true
  },
  slider__minValue: {
    grow: true,
    "!max-w-[45%]": true,
    "mb-0": true,
    "[&>div>div]:relative": true,
    '[&>div>div::after]:content-[""]': true,
    "[&>div>div::after]:absolute": true,
    "[&>div>div::after]:top-1/2": true,
    "[&>div>div::after]:left-[105.5%]": true,
    "[&>div>div::after]:w-[12%]": true,
    "[&>div>div::after]:h-[1px]": true,
    "[&>div>div::after]:bg-neutral-400": true,
    "dark:[&>div>div::after]:bg-neutral-500": true
  },
  slider__maxValue: {
    grow: true,
    "!max-w-[45%]": true,
    "mb-0": true,
    relative: true
  },
  slider__chart: {
    relative: true,
    "z-20": true,
    "mb-2": true,
    flex: true,
    "justify-between": true,
    "items-center": true,
    "w-full": true,
    "aspect-[3/1]": true
  },
  slider__chartBar: {
    absolute: true,
    "bottom-0": true,
    "h-full": true,
    "bg-neutral-400": true,
    "data-[active=false]:bg-neutral-300": true,
    "dark:bg-neutral-500": true,
    "dark:data-[active=false]:bg-neutral-600": true
  },
  taglist__inner: {
    "py-2": true,
    "pr-0": true,
    "pl-0": true
  },
  taglist__tags: {
    "pl-3": true
  },
  taglist__tagWrapper: {
    "[&.formkit-dropZone_.formkit-tag]:opacity-25": true,
    "[&.formkit-touchDropZone_.formkit-tag]:opacity-25": true
  },
  taglist__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "!p-0": true,
    "!w-[0%]": true,
    "min-w-[1em]": true,
    "inline-block": true,
    "-mt-1": true,
    "first:mt-0": true,
    "first:mb-1": true
  },
  taglist__listboxButton: {
    "ml-auto": true,
    "shrink-0": true
  },
  toggle__outer: {
    group: true,
    "min-w-0": true,
    grow: true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "text-base": true,
    "max-w-none": true
  },
  toggle__altLabel: {
    block: true,
    "w-full": true,
    "mb-1.5": true,
    "font-bold": true,
    "text-xs": true,
    "text-neutral-700": true,
    "dark:text-neutral-300": false
  },
  toggle__inner: {
    peer: true,
    "inline-block": true,
    "mr-2": true
  },
  toggle__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    peer: true,
    absolute: true,
    "opacity-0": true,
    "w-0": true,
    "h-0": true
  },
  toggle__label: {
    block: true,
    "text-neutral-700": true,
    "text-sm": true,
    "font-bold": true,
    "mb-1": true,
    "dark:text-neutral-300": false,
    "peer-first:font-normal": true,
    "peer-first:mb-0": true
  },
  toggle__innerLabel: {
    absolute: true,
    "text-neutral-200": true,
    "text-[10px]": true,
    "font-bold": true,
    "select-none": true,
    "left-full": true,
    "top-1/2": true,
    "-translate-x-full": true,
    "-translate-y-1/2": true,
    "px-1": true
  },
  toggle__thumb: {
    relative: true,
    "p-0.5": true,
    "left-0": true,
    "aspect-[1/1]": true,
    "rounded-full": true,
    "transition-all": true,
    "w-[1.25em]": true,
    "bg-neutral-50": true,
    "text-neutral-600": true,
    "shadow-base": true
  },
  toggle__track: {
    "p-0.5": true,
    "min-w-[3em]": true,
    relative: true,
    "cursor-pointer": true,
    "select-none": true,
    "rounded-full": true,
    "transition-all": true,
    "bg-neutral-400": true,
    "peer-checked:bg-blue-600": true,
    "peer-checked:[&>div:last-child]:left-full": true,
    "peer-checked:[&>div:last-child]:-translate-x-full": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:left-0": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0": true,
    "shadow-sm": true,
    "peer-focus-visible:ring-2": true,
    "peer-focus-visible:ring-blue-500": true,
    "peer-focus-visible:ring-offset-2": true,
    "dark:bg-neutral-500": true
  },
  toggle__valueLabel: {
    "font-bold": true,
    "text-xs": true,
    "text-neutral-700": true,
    "dark:text-neutral-300": false
  },
  toggle__wrapper: {
    flex: true,
    "flex-wrap": true,
    "items-center": true,
    "mb-1.5": true
  },
  togglebuttons__wrapper: {
    "mb-1.5": true
  },
  togglebuttons__options: {
    "group/options": true,
    "inline-flex": true,
    "data-[vertical=true]:flex-col": true
  },
  togglebuttons__option: {
    "group/option": true
  },
  togglebuttons__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "!px-4": true,
    "!mb-0": true,
    relative: true,
    "focus:z-10": true,
    "group-data-[vertical=true]/options:w-full": true,
    "justify-center": true,
    "bg-blue-50": true,
    "disabled:opacity-50": true,
    "disabled:cursor-not-allowed": true,
    "group-data-[disabled]:disabled:opacity-100": true,
    "dark:bg-transparent": true,
    "dark:disabled:bg-transparent": true,
    "dark:disabled:text-blue-500": true,
    "dark:text-blue-500": true,
    "aria-[pressed=true]:bg-blue-600": true,
    "aria-[pressed=true]:text-white": true,
    "dark:aria-[pressed=true]:bg-blue-600": true,
    "dark:aria-[pressed=true]:text-white": true,
    "group-[]/option:!rounded-none": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-tr-none":
      true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-br-none":
      true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-bl-none":
      true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-br-none":
      true,
    "group-data-[vertical=false]/options:group-last/option:!rounded": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-tl-none":
      true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-bl-none":
      true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tl-none":
      true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tr-none":
      true,
    "group-data-[vertical=false]/options:group-[]/option:!border-r-0": true,
    "group-data-[vertical=false]/options:group-last/option:!border-r": true,
    "group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-blue-500":
      true,
    "group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-blue-600":
      true,
    "group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-blue-600":
      true,
    "dark:group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-blue-600":
      true,
    "dark:group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-blue-600":
      true,
    "dark:group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-blue-600":
      true,
    "group-data-[vertical=true]/options:group-[]/option:!border-b-0": true,
    "group-data-[vertical=true]/options:group-last/option:!border-b": true,
    "group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-blue-500":
      true,
    "group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-blue-600":
      true,
    "group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-blue-600":
      true,
    "dark:group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-blue-600":
      true,
    "dark:group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-blue-600":
      true,
    "dark:group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-blue-600":
      true
  },
  transferlist__outer: {
    group: true,
    "min-w-0": true,
    grow: true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "text-base": true,
    "max-w-none": true,
    "[&_.dnd-placeholder]:bg-blue-600": true,
    "[&_.dnd-placeholder]:text-white": true
  },
  transferlist__wrapper: {
    flex: true,
    "flex-col": true,
    "sm:flex-row": true,
    "justify-between": true,
    "w-full": true,
    "max-w-none": true
  },
  transferlist__help: {
    "text-neutral-500": true,
    "text-xs": true,
    "dark:text-neutral-400": false,
    "pb-2": true
  },
  transferlist__transferlist: {
    grow: true,
    shrink: true,
    "min-w-0": true,
    shadow: true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "aspect-[4/5]": true,
    flex: true,
    "flex-col": true,
    "h-[350px]": true,
    border: true,
    "border-neutral-300": true,
    rounded: true,
    "overflow-hidden": true,
    "select-none": true,
    "[&:has(:focus-visible)]:ring-1": true,
    "[&:has(:focus-visible)]:ring-blue-500": true,
    "dark:border-neutral-600": true,
    "dark:bg-neutral-900/50": true
  },
  transferlist__transferlistHeader: {
    flex: true,
    "bg-neutral-100": true,
    "text-neutral-600": true,
    "text-sm": true,
    "justify-between": true,
    "items-center": true,
    "border-b": true,
    "border-neutral-300": true,
    "py-2": true,
    "px-2.5": true,
    "dark:bg-neutral-700": true,
    "dark:border-neutral-600": true,
    "dark:text-neutral-400": false
  },
  transferlist__transferlistHeaderItemCount: {
    "ml-auto": true,
    "text-xs": true,
    "min-w-[1.5em]": true,
    "[line-height:1.5em]": true,
    "px-2": true,
    "text-center": true,
    "rounded-xl": true,
    "bg-neutral-200": true,
    "text-neutral-700": true,
    "dark:bg-neutral-500": true,
    "dark:text-neutral-300": false
  },
  transferlist__transferlistListItems: {
    "list-none": true,
    "bg-white": true,
    "h-full": true,
    "overflow-x-hidden": true,
    "overflow-y-auto": true,
    "dark:bg-transparent": true,
    "outline-none": true
  },
  transferlist__transferlistListItem: {
    "py-2": true,
    "px-2": true,
    "text-neutral-700": true,
    "ring-1": true,
    "ring-neutral-200": true,
    "aria-selected:bg-green-main": true,
    "data-[is-active=true]:bg-green-main": true,
    "data-[is-active=true]:ring-blue-200": true,
    "aria-selected:ring-blue-200": true,
    relative: true,
    flex: true,
    "cursor-pointer": true,
    "items-center": true,
    "bg-white": true,
    "pl-[1.5em]": true,
    "first:-mt-px": true,
    "first:border-t": true,
    "aria-selected:z-[2]": true,
    "aria-selected:border-transparent": true,
    "aria-selected:ring-1": true,
    "data-[is-active=true]:z-[2]": true,
    "data-[is-active=true]:border-transparent": true,
    "data-[is-active=true]:ring-1": true,
    "group-data-[is-max=true]:cursor-not-allowed": true,
    "dark:bg-neutral-800": true,
    "dark:text-neutral-300": false,
    "dark:data-[is-active=true]:bg-blue-900": true,
    "dark:aria-selected:bg-blue-900": true,
    "dark:ring-neutral-700": true,
    "dark:data-[is-active=true]:ring-blue-600": true,
    "dark:aria-selected:ring-blue-600": true,
    "[&.formkit-dropZone]:bg-green-main": true,
    "[&.formkit-selectionDropZone]:bg-green-main": true,
    "[&.formkit-touchDropZone]:bg-green-main": true,
    "[&.formkit-touchSelectionDropZone]:bg-green-main": true,
    "[&.formkit-longTouch]:bg-green-main": true,
    "dark:[&.formkit-dropZone]:bg-blue-900": true,
    "dark:[&.formkit-selectionDropZone]:bg-blue-900": true,
    "dark:[&.formkit-touchDropZone]:bg-blue-900": true,
    "dark:[&.formkit-touchSelectionDropZone]:bg-blue-900": true,
    "dark:[&.formkit-longTouch]:bg-blue-900": true
  },
  transferlist__transferlistOption: {
    "text-sm": true
  },
  transferlist__transferControls: {
    "inline-flex": true,
    "grow-0": true,
    shrink: true,
    border: true,
    "border-neutral-300": true,
    "flex-row": true,
    "sm:flex-col": true,
    "justify-center": true,
    "my-2": true,
    "sm:my-auto": true,
    "mx-auto": true,
    "sm:mx-2": true,
    rounded: true,
    "overflow-clip": true,
    "shadow-none": true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "dark:border-neutral-800": true
  },
  transferlist__sourceEmptyMessage: {
    "appearance-none": true,
    "border-none": true,
    "w-full": true,
    "my-2": true,
    "text-center": true,
    "text-neutral-500": true,
    italic: true
  },
  transferlist__sourceListItems: {
    "group-data-[is-max=true]:opacity-50": true
  },
  transferlist__targetEmptyMessage: {
    "appearance-none": true,
    "border-none": true,
    "w-full": true,
    "my-2": true,
    "text-center": true,
    "text-neutral-500": true,
    italic: true
  },
  transferlist__emptyMessageInner: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "p-2": true,
    "text-sm": true
  },
  transferlist__transferlistControls: {
    "bg-white": true,
    "p-2": true,
    "border-b": true,
    "border-neutral-200": true,
    "dark:bg-neutral-700": true,
    "dark:border-neutral-700": true
  },
  transferlist__transferlistSearch: {
    flex: true,
    border: true,
    "border-neutral-300": true,
    rounded: true,
    "items-center": true,
    "text-neutral-700": true,
    "selection:bg-green-main": true,
    "dark:border-neutral-600": true,
    "dark:text-neutral-300": false,
    "dark:selection:bg-green-main": true,
    "dark:selection:text-neutral-700": true,
    "dark:bg-neutral-800": true
  },
  transferlist__transferlistSearchInput: {
    "border-none": true,
    "px-2": true,
    "py-1.5": true,
    "w-full": true,
    "bg-transparent": true,
    "outline-none": true,
    "text-sm": true
  },
  transferlist__transferlistSearchClear: {
    flex: true,
    "w-[0.75em]": true,
    "mr-2": true,
    "[&_svg]:w-full": true
  },
  transferlist__controlLabel: {
    absolute: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true
  },
  transferlist__selectedIcon: {
    "w-[0.75em]": true,
    absolute: true,
    "left-[0.5em]": true,
    "select-none": true,
    "text-blue-600": true,
    "dark:text-blue-500": true
  },
  transferlist__transferlistButton: {
    "sm:w-5": true,
    relative: true,
    flex: true,
    "justify-center": true,
    "text-sm": true,
    "shrink-0": true,
    "box-content": true,
    "text-neutral-700": true,
    "disabled:bg-neutral-200": true,
    "disabled:!text-neutral-400": true,
    "bg-neutral-50": true,
    "hover:text-blue-600": true,
    "cursor-pointer": true,
    "appearance-none": true,
    "border-none": true,
    "p-2.5": true,
    "hover:z-10": true,
    "disabled:cursor-not-allowed": true,
    "disabled:opacity-50": true,
    "disabled:hover:text-current": true,
    "disabled:hover:outline-none": true,
    "focus-visible:ring-1": true,
    "focus-visible:ring-blue-500": true,
    "focus-visible:z-10": true,
    "dark:bg-neutral-800": true,
    "dark:text-neutral-400": false,
    "dark:disabled:!text-neutral-600": true,
    "dark:disabled:bg-neutral-900": true,
    "dark:disabled:hover:text-current": true,
    "dark:disabled:hover:outline-none": true,
    "dark:hover:text-blue-500": true
  },
  transferlist__fastForwardIcon: {
    "w-4": true,
    flex: true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  transferlist__moveRightIcon: {
    "w-4": true,
    flex: true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  transferlist__moveLeftIcon: {
    "w-4": true,
    flex: true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  transferlist__rewindIcon: {
    "w-4": true,
    flex: true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  transferlist__messages: {
    "mt-2": true
  },
  barcode__barcodeIcon: {
    "w-[1.5em]": true,
    "text-neutral-700": true,
    "cursor-pointer": true,
    "dark:text-neutral-300": false
  },
  barcode__dialog: {
    "border-none": true,
    "outline-none": true,
    "overflow-clip": true,
    "p-0": true,
    "bg-black": true,
    rounded: true,
    "w-[100%-2rem]": true,
    "max-w-[30rem]": true,
    "[&::backdrop]:bg-neutral-800/50": true
  },
  barcode__video: {
    "w-full": true,
    "aspect-[1/1]": true,
    "object-cover": true,
    block: true,
    "pointer-events-none": true
  },
  barcode__closeIcon: {
    "cursor-pointer": true,
    absolute: true,
    "bg-white": true,
    "color-neutral-700": true,
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    rounded: true,
    flex: true,
    "top-2": true,
    "right-2": true,
    "z-20": true,
    "[&>svg]:w-[1.25em]": true,
    "[&>svg]:h-[1.25em]": true,
    "[&>svg]:m-auto": true
  },
  barcode__overlay: {
    "text-neutral-700": true,
    "dark:text-neutral-300": false,
    absolute: true,
    "top-1/2": true,
    "left-1/2": true,
    "w-[min(20em,75%)]": true,
    "aspect-[1/1]": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    rounded: true,
    "pointer-events-none": true,
    "shadow-[0_0_0_999em_rgba(0,0,0,0.5)]": true
  },
  barcode__overlayDecorators: {
    absolute: true,
    "inset-0": true,
    "z-10": true
  },
  barcode__overlayDecoratorTopLeft: {
    absolute: true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    rounded: true,
    "top-0": true,
    "left-0": true,
    "border-l-4": true,
    "border-t-4": true,
    "rounded-tr-none": true,
    "rounded-bl-none": true
  },
  barcode__overlayDecoratorTopRight: {
    absolute: true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    rounded: true,
    "top-0": true,
    "right-0": true,
    "border-r-4": true,
    "border-t-4": true,
    "rounded-tl-none": true,
    "rounded-br-none": true
  },
  barcode__overlayDecoratorBottomRight: {
    absolute: true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    rounded: true,
    "bottom-0": true,
    "right-0": true,
    "border-r-4": true,
    "border-b-4": true,
    "rounded-tr-none": true,
    "rounded-bl-none": true
  },
  barcode__overlayDecoratorBottomLeft: {
    absolute: true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    rounded: true,
    "bottom-0": true,
    "left-0": true,
    "border-l-4": true,
    "border-b-4": true,
    "rounded-tl-none": true,
    "rounded-br-none": true
  },
  "multi-step__outer": {
    group: true,
    "min-w-0": true,
    grow: true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "text-base": true,
    "group/multistep": true,
    "max-w-[32rem]": true
  },
  "multi-step__wrapper": {
    "group/wrapper": true,
    "data-[tab-style=tab]:shadow": true,
    "data-[tab-style=tab]:rounded": true
  },
  "multi-step__tabs": {
    flex: true,
    "items-center": true,
    "group-data-[tab-style=tab]/wrapper:overflow-auto": true,
    "group-data-[tab-style=tab]/wrapper:border": true,
    "group-data-[tab-style=tab]/wrapper:border-b-0": true,
    "group-data-[tab-style=tab]/wrapper:border-neutral-300": true,
    "group-data-[tab-style=tab]/wrapper:rounded": true,
    "group-data-[tab-style=tab]/wrapper:rounded-bl-none": true,
    "group-data-[tab-style=tab]/wrapper:rounded-br-none": true,
    "dark:group-data-[tab-style=tab]/wrapper:border-neutral-600": true,
    "group-data-[tab-style=progress]/wrapper:my-6": true,
    "group-data-[tab-style=progress]/wrapper:justify-around": true,
    "group-data-[tab-style=progress]/wrapper:overflow-visible": true,
    "group-data-[tab-style=progress]/wrapper:group-data-[hide-labels=true]/wrapper:mb-3.5":
      true
  },
  "multi-step__tab": {
    "group/tab": true,
    "group-data-[tab-style=tab]/wrapper:relative": true,
    "group-data-[tab-style=tab]/wrapper:flex": true,
    "group-data-[tab-style=tab]/wrapper:grow": true,
    "group-data-[tab-style=tab]/wrapper:text-sm": true,
    "group-data-[tab-style=tab]/wrapper:items-center": true,
    "group-data-[tab-style=tab]/wrapper:justify-center": true,
    "group-data-[tab-style=tab]/wrapper:cursor-pointer": true,
    "group-data-[tab-style=tab]/wrapper:text-neutral-700": true,
    "group-data-[tab-style=tab]/wrapper:bg-neutral-100": true,
    "group-data-[tab-style=tab]/wrapper:py-3.5": true,
    "group-data-[tab-style=tab]/wrapper:px-4": true,
    "group-data-[tab-style=tab]/wrapper:border-r": true,
    "group-data-[tab-style=tab]/wrapper:border-b": true,
    "group-data-[tab-style=tab]/wrapper:border-neutral-300": true,
    "group-data-[tab-style=tab]/wrapper:last:border-r-0": true,
    "group-data-[tab-style=tab]/wrapper:shadow-[inset_0_-0.5em_0.5em_-0.5em_rgba(0,0,0,0.1)]":
      true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:bg-white": true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:font-bold": true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:border-b-white":
      true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:z-10": true,
    "group-data-[tab-style=tab]/wrapper:data-[active=true]:shadow-[0_0_0.5em_0_rgba(0,0,0,0.1)]":
      true,
    "dark:group-data-[tab-style=tab]/wrapper:text-neutral-300": true,
    "dark:group-data-[tab-style=tab]/wrapper:bg-neutral-950/20": true,
    "dark:group-data-[tab-style=tab]/wrapper:data-[active=true]:bg-transparent":
      true,
    "dark:group-data-[tab-style=tab]/wrapper:data-[active=true]:border-b-transparent":
      true,
    "dark:group-data-[tab-style=tab]/wrapper:border-neutral-600": true,
    "group-data-[tab-style=progress]/wrapper:flex": true,
    "group-data-[tab-style=progress]/wrapper:flex-col": true,
    "group-data-[tab-style=progress]/wrapper:items-center": true,
    "group-data-[tab-style=progress]/wrapper:grow": true,
    "group-data-[tab-style=progress]/wrapper:shrink-0": true,
    "group-data-[tab-style=progress]/wrapper:relative": true,
    "group-data-[tab-style=progress]/wrapper:before:block": true,
    "group-data-[tab-style=progress]/wrapper:before:text-sm": true,
    "group-data-[tab-style=progress]/wrapper:before:w-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:before:h-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:before:border-4": true,
    "group-data-[tab-style=progress]/wrapper:before:border-neutral-300": true,
    "group-data-[tab-style=progress]/wrapper:before:rounded-full": true,
    "group-data-[tab-style=progress]/wrapper:before:bg-white": true,
    "group-data-[tab-style=progress]/wrapper:before:z-10": true,
    "dark:group-data-[tab-style=progress]/wrapper:before:border-neutral-600":
      true,
    "dark:group-data-[tab-style=progress]/wrapper:before:bg-neutral-950": true,
    "group-data-[tab-style=progress]/wrapper:after:block": true,
    "group-data-[tab-style=progress]/wrapper:after:h-1": true,
    "group-data-[tab-style=progress]/wrapper:after:w-full": true,
    "group-data-[tab-style=progress]/wrapper:after:absolute": true,
    "group-data-[tab-style=progress]/wrapper:after:top-[0.5em]": true,
    "group-data-[tab-style=progress]/wrapper:after:left-[calc(50%+0.5em)]":
      true,
    "group-data-[tab-style=progress]/wrapper:after:bg-neutral-300": true,
    "group-data-[tab-style=progress]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-blue-600":
      true,
    "group-data-[tab-style=progress]/wrapper:last:after:hidden": true,
    "dark:group-data-[tab-style=progress]/wrapper:after:bg-neutral-600": true,
    "dark:group-data-[tab-style=progress]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-blue-600":
      true
  },
  "multi-step__tabLabel": {
    "group-data-[tab-style=progress]/wrapper:absolute": true,
    "group-data-[tab-style=progress]/wrapper:text-neutral-800": true,
    "group-data-[tab-style=progress]/wrapper:top-full": true,
    "group-data-[tab-style=progress]/wrapper:w-full": true,
    "group-data-[tab-style=progress]/wrapper:whitespace-nowrap": true,
    "group-data-[tab-style=progress]/wrapper:text-xs": true,
    "dark:group-data-[tab-style=progress]/wrapper:text-neutral-300": true
  },
  "multi-step__badge": {
    "bg-red-600": true,
    absolute: true,
    "font-mono": true,
    "font-bold": true,
    flex: true,
    "items-center": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "[line-height:1.25rem]": true,
    "text-white": true,
    "rounded-full": true,
    "group-data-[valid=true]/tab:bg-blue-600": true,
    "group-data-[tab-style=tab]/wrapper:text-[0.66rem]": true,
    "group-data-[tab-style=tab]/wrapper:p-1.5": true,
    "group-data-[tab-style=tab]/wrapper:w-5": true,
    "group-data-[tab-style=tab]/wrapper:h-5": true,
    "group-data-[tab-style=tab]/wrapper:top-1.5": true,
    "group-data-[tab-style=tab]/wrapper:right-1.5": true,
    "group-data-[tab-style=progress]/wrapper:w-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:h-[1.25rem]": true,
    "group-data-[tab-style=progress]/wrapper:p-1": true,
    "group-data-[tab-style=progress]/wrapper:text-[10px]": true,
    "group-data-[tab-style=progress]/wrapper:[line-height:0]": true,
    "group-data-[tab-style=progress]/wrapper:z-10": true
  },
  "multi-step__validStepIcon": {
    "w-full": true,
    "h-full": true,
    "mt-0.5": true
  },
  "multi-step__steps": {
    "px-10": true,
    "pt-8": true,
    "pb-4": true,
    "bg-white": true,
    border: true,
    "border-neutral-300": true,
    rounded: true,
    "dark:bg-transparent": true,
    "dark:border-neutral-600": true,
    "group-data-[tab-style=tab]/wrapper:border-t-0": true,
    "group-data-[tab-style=tab]/wrapper:rounded-tl-none": true,
    "group-data-[tab-style=tab]/wrapper:rounded-tr-none": true,
    "group-data-[tab-style=progress]/wrapper:shadow": true,
    "[&_[data-type]]:max-w-none": true
  },
  step__stepActions: {
    flex: true,
    "justify-between": true,
    "[&>*]:grow-0": true
  },
  step__stepPrevious: {
    "mr-1.5": true
  },
  step__stepNext: {
    "ml-auto": true
  }
}

/**
 * Globals are merged prior to generating this file — these are included for
 * any other non-matching inputs.
 **/
const globals: Record<string, Record<string, boolean>> = {
  outer: {
    group: true,
    "max-w-none": false,
    "min-w-0": false,
    grow: false,
    "data-[disabled]:select-none": false,
    "data-[disabled]:opacity-50": false,
    "text-base": false
  },
  label: {
    block: true,
    "text-neutral-700": true,
    "text-sm": true,
    "font-bold": true,
    "mb-1": true,
    "dark:text-neutral-300": false
  },
  legend: {
    block: true,
    "text-neutral-700": true,
    "text-sm": true,
    "font-bold": true,
    "dark:text-neutral-300": false
  },
  input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": false,
    "selection:bg-green-main": true,
    "selection:text-neutral-700": true,
    "group-data-[has-overlay]:selection:!text-transparent": true
  },
  prefixIcon: {
    flex: true,
    "items-center": true,
    "-ml-1": true,
    "mr-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  suffixIcon: {
    flex: true,
    "items-center": true,
    "-mr-1": true,
    "ml-2": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  loaderIcon: {
    "animate-spin": true,
    flex: true,
    "items-center": true,
    "my-auto": true,
    "ml-2": true,
    "text-base": true,
    "text-neutral-500": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  loadMoreInner: {
    flex: true,
    "text-sm": true,
    "text-neutral-500": true,
    "p-2": true,
    "items-center": true,
    "justify-center": true,
    "[&>span]:mr-2": true
  },
  help: {
    "text-neutral-500": true,
    "text-xs": true,
    "dark:text-neutral-400": false
  },
  message: {
    "text-red-600": true,
    "mb-1.5": true,
    "text-xs": true,
    "dark:text-red-400": true
  },
  overlay: {
    "text-neutral-700": true,
    "dark:text-neutral-300": false
  },
  overlayPlaceholder: {
    "text-neutral-400": true,
    "dark:text-neutral-400/50": true
  },
  overlayLiteral: {
    "text-neutral-700": true,
    "dark:text-neutral-300": false
  },
  overlayChar: {
    "text-neutral-700": true,
    "dark:text-neutral-300": false
  },
  overlayEnum: {
    "text-neutral-700": true,
    "dark:text-neutral-300": false
  }
}
