// formkit.config.ts
import { it } from "@formkit/i18n"
import { rootClasses } from "./formkit.theme"
import { defineFormKitConfig, DefaultConfigOptions } from "@formkit/vue"
import { createProPlugin, inputs } from "@formkit/pro"
import { createFloatingLabelsPlugin } from "@formkit/addons"
import "@formkit/addons/css/floatingLabels"

export default defineFormKitConfig(() => {
  const getProKey = (): string =>
    (process.env?.FORMKIT_PRO_KEY as string) ??
    useRuntimeConfig().public.FORMKIT_PRO_KEY

  const pro = createProPlugin(getProKey(), inputs)
  const floatingLabels = createFloatingLabelsPlugin()
  return {
    config: {
      locales: { it },
      locale: "it",
      rootClasses
    },
    plugins: [pro, floatingLabels]
  } as Partial<DefaultConfigOptions>
})
